import { authorizationEndpoints } from '@redux/features/authorization/api'
import {
  setCurrentStep,
  setIsLoadingAuth,
  setIsOpenAuthorizationPopup,
} from '@redux/features/authorization/authorization.slice'
import { setNotification } from '@redux/features/modals/modals.slice'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import type { ResponseErrorType } from '@Types/common/responseError'
import { AuthorizationStep } from '@components/authorization/constant/steps'
import type { LoginFormType } from '@utils/forms/authorization/login'
import { AccountResetType } from '@utils/forms/authorization/resetPassword'

export default createAsyncThunk<void, { isExtended?: boolean; loginForm: LoginFormType }, { state: RootState }>(
  'authorization/resetPassword',
  ({ isExtended, loginForm }, { dispatch }) => {
    dispatch(setIsLoadingAuth(true))
    void dispatch(
      authorizationEndpoints.postAccountReset.initiate({
        email: loginForm.email || '',
        reset_type: AccountResetType.LoginCode,
      })
    )
      .unwrap()
      .then(response => {
        if (response.success) {
          if (isExtended) {
            dispatch(setCurrentStep(AuthorizationStep.LOGIN_BY_CODE_EXTENDED))
            dispatch(setIsOpenAuthorizationPopup(true))
          } else {
            dispatch(setCurrentStep(AuthorizationStep.CHECK_EMAIL_NOTIFICATION))
          }
        }
      })
      .catch((error: ResponseErrorType) => {
        dispatch(
          setNotification({
            description: error.data?.message,
            message: 'somethingWrong',
            type: 'error',
          })
        )
      })
      .finally(() => {
        dispatch(setIsLoadingAuth(false))
      })
  }
)
