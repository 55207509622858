import { authorizationEndpoints } from '@redux/features/authorization/api'
import { setNotification } from '@redux/features/modals/modals.slice'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

export default createAsyncThunk<void, void, { state: RootState }>('authorization/logout', (_, { dispatch }) => {
  void dispatch(authorizationEndpoints.postLogout.initiate()).then(() => {
    dispatch(
      setNotification({
        message: 'logoutNotification',
        type: 'info',
      })
    )
  })
})
