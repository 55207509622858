import getConfig from 'next/config'
import Script from 'next/script'
import type { FC } from 'react'

import { setIsGTMLoaded } from '@redux/features/analytics/analytics.slice'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectIsPageInteraction } from '@reduxCommonApp/commonApp.selectors'

const {
  publicRuntimeConfig: { googleTagManagerId },
} = getConfig()

type Props = {
  isGTMLazyLoading?: boolean
}

const GTMScripts: FC<Props> = ({ isGTMLazyLoading }) => {
  const dispatch = useAppDispatch()
  const isPageInteraction = useAppSelector(selectIsPageInteraction)
  if (!googleTagManagerId || (!isPageInteraction && isGTMLazyLoading)) return

  const onLoad = () => void dispatch(setIsGTMLoaded(true))

  return (
    <Script
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${googleTagManagerId}');`,
      }}
      onReady={onLoad}
      strategy={isGTMLazyLoading ? 'lazyOnload' : 'afterInteractive'}
    />
  )
}

export default GTMScripts
